/* eslint-disable quote-props */
const colorDict = {
  'no-helmet': '#FF4D4F',
  'helmet': '#6e7dff',
  'vest': '#FF4D4F',
  'no-vest': 'orange',
  'person': '#37E0CC',
  'other': 'black',
};

export default colorDict;
